<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <ValidationObserver ref="addEmoji">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors }"
              class="md-layout-item md-size-100"
              mode="passive"
              tag="div"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Название</label>
                <md-input v-model="name" />
              </md-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              class="md-layout-item md-size-100"
              mode="passive"
              tag="div"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Сообщение</label>
                <md-input v-model="message" />
              </md-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              class="md-layout-item md-size-100"
              mode="passive"
              tag="div"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Изображение</label>
                <md-file v-model="file" accept=".jpeg, .png" />
                <span class="md-helper-text">
                  Файл должен быть форматов JPEG, PNG и весить до 15 мегабайт
                </span>
              </md-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              class="md-layout-item md-size-100"
              mode="passive"
              tag="div"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>URL</label>
                <md-input v-model="URL" />
              </md-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              class="md-layout-item md-size-100"
              mode="passive"
              tag="div"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Дата отправки</label>
                <DatePicker
                  v-model="date"
                  prefix-class="xmx"
                  type="datetime"
                  :show-second="false"
                  lang="ru"
                  :popup-style="{
                    left: 0,
                  }"
                  :append-to-body="false"
                  value-type="format"
                  format="DD.MM.YYYY HH:mm"
                >
                  <template #input>
                    <md-input v-model="date" class="w-full" />
                  </template>
                </DatePicker>
              </md-field>
            </ValidationProvider>
            <div class="buttons-wrap justify-end">
              <md-button class="md-raised md-success"> Создать </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      message: "",
      name: "",
      URL: "",
      file: null,
      date: null,
    };
  },
};
</script>

<style scoped>
.buttons-wrap {
  display: flex;
  align-items: center;
}

.spin {
  margin-left: 20px;
}

.cancel-btn {
  margin-left: 10px !important;
}
.m-0 {
  margin: 0 !important;
}

.xmx-datepicker,
.w-full {
  width: 100%;
}
</style>
